import React, {FunctionComponent} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Subheader from "../components/subheader";
import {Tag} from "../utils/models";
import {Card} from "../components/card";
import slugify from "slugify";
import {Grid} from "../components/common";
import styled from "styled-components";
import SEO from "../components/seo";

interface TagsPageProps {
  data: {
    tags: {
      items: Array<Tag>;
    };
  };
  location: Location;
}

const TagName = styled.p`
  margin: 0 !important;
`;

const TagsPage: FunctionComponent<TagsPageProps> = ({data, location}) => {
  const tags = data.tags.items.map(tag => tag.name);

  return (
    <Layout bigHeader={false}>
      <SEO
        location={location}
        title={`Tags`}
        type={`Series`}
      />
      <Subheader title={`Tags`} subtitle={`${tags.length} tags`}/>
      <Grid columns={6}>
        {tags.map((tag, index) => (
          <Card
            key={index}
            path={`/tag/${slugify(tag, {lower: true})}`}
            compact={true}
            style={{textAlign: 'center'}}
          >
            <TagName>{tag}</TagName>
          </Card>
        ))}
      </Grid>
    </Layout>
  );
};

export default TagsPage;

export const query = graphql`
  query {
    tags: allMarkdownRemark {
      items: group(field: frontmatter___tags) {
        name: fieldValue
        totalCount
      }
    }
  }
`;
